<template>
    <div>
        <div class="top">
            <div class="login">
                <a href="#">登录</a>
            </div>
            <div class="bl-cont">
                <div class="logo-cont">
                    <img class="logo" src="@/assets/image/logo.svg" alt="Blove logo" />
                </div>
            </div>
            <div class="bl-cont logo-title">
                <h1>Blove For You</h1>
            </div>
            <div class="bl-cont">
                <div class="top-text">
                    “Blove For You”
                    一种对生活细节的深情体会与感恩，它教会了我们在平凡的日常中发现非凡的美好。清晨的微笑、忙碌的奔波、不懈的坚持，正是我们对生活的热爱与执着。面对任何风浪，我们勇敢前行。正是这些点点滴滴，留下了我们走过的痕迹，铸造了人生的不平凡。
                </div>
            </div>
            <div ref="platformCont" class="round_div_all">
                <div v-for="item in platforms" :key="item.title">
                    <div class="round_div">
                        <div class="r-s01">
                            <a :href="item.url" target="_blank">
                                <i class="bl-icon icon-platform" :class="`bl-icon-${item.icon}`"></i>
                            </a>
                        </div>
                        <div class="r-s02">{{ item.title }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div ref="squareCont" class="mian01">
                <div v-for="item in links" :key="item.title">
                    <div class="square_div">
                        <a :href="item.url" target="_blank">
                            <div class="bl-cont squareCont">
                                <div class="s-s01">
                                    <i class="bl-icon icon-link" :class="`bl-icon-${item.icon}`"></i>
                                </div>
                                <div>
                                    <div class="s-s02">{{ item.title }}</div>
                                    <div class="s-s03">{{ item.desc }}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <footer class="down">
                <span class="f82">安全 | 服务条款 | 使用规范 | 客服中心 | 隐私保护指引</span><br />
                <span class="f75">Copyright © 1998-2024 All Rights Reserved.</span>
            </footer>
            <div class="banquan">
                <audio ref="audioPlayer" src="@/assets/music/凡人诀.mp3"></audio>
                <div>
                    <i class="music-icon bl-icon bl-icon-Music-1"></i>
                    <i class="music-action-icon bl-icon bl-icon-skip-previous-fill" @click="onPrev"></i>
                    <i v-if="isPlaying" class="music-action-icon bl-icon bl-icon-pause1" @click="onPause"></i>
                    <i v-else class="music-action-icon bl-icon bl-icon-play-arrow-fill" @click="onStart"></i>
                    <i class="music-action-icon bl-icon bl-icon-skip-next-fill" @click="onNext"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { datas } from '@/assets/data';

export default {
    name: 'BlMusic',
    props: {},
    computed: {},
    watch: {},
    data() {
        return {
            platforms: [
                {
                    title: 'Android',
                    icon: 'android',
                    url: ''
                },
                {
                    title: 'IOS',
                    icon: 'macos',
                    url: ''
                },
                {
                    title: 'Windows',
                    icon: 'Windows',
                    url: ''
                },
                {
                    title: 'Harmony OS',
                    icon: 'macos',
                    url: ''
                }
            ],
            links: [
                {
                    title: 'Blove',
                    icon: 'website1',
                    url: 'http://www.blove.site',
                    desc: 'blove for you'
                },
                {
                    title: 'Cpolar',
                    icon: 'website1',
                    url: 'https://i.cpolar.com/m/4OQA',
                    desc: '公开一个本地Web站点至公网'
                },
                {
                    title: 'Google',
                    icon: 'a-guge1',
                    url: 'https://www.google.com/'
                },
                {
                    title: 'Microsoft',
                    icon: 'weiruan',
                    url: 'https://www.microsoft.com/'
                },
                {
                    title: 'MySQL',
                    icon: 'mysql',
                    url: 'https://www.mysql.com'
                },
                {
                    title: 'ProtonVPN',
                    icon: 'protonvpn',
                    url: 'http://www.blove.site'
                },
                {
                    title: 'Tinypng',
                    icon: 'tinypng',
                    url: 'https://tinypng.com/'
                },
                {
                    title: 'ConvertICO',
                    icon: 'icon_convertto',
                    url: 'https://convertico.com/'
                },
                {
                    title: 'PicSVG',
                    icon: 'picsvg',
                    url: 'https://picsvg.com/'
                },
                {
                    title: 'Spotify',
                    icon: 'spotify',
                    url: 'https://www.spotify.com/'
                },
                {
                    title: '阿里云-镜像',
                    icon: 'aliyun',
                    url: 'https://developer.aliyun.com/mirror/'
                },
                {
                    title: '中国科技大学-镜像',
                    icon: 'jingxiang',
                    url: 'https://mirrors.ustc.edu.cn/'
                },
                {
                    title: '华为云-镜像',
                    icon: 'jingxiang',
                    url: 'https://mirrors.huaweicloud.com/home'
                },
                {
                    title: 'PDF24 Tools',
                    icon: 'website1',
                    url: 'https://www.pdf24.org/zh/'
                },
                {
                    title: 'ImagesTool',
                    icon: 'website1',
                    url: 'https://imagestool.com/zh_CN/'
                },
                {
                    title: 'Chiphell',
                    icon: 'website1',
                    url: 'https://www.chiphell.com/'
                }
            ],
            isPlaying: false,
            musics: [
                require('@/assets/music/凡人诀.mp3'),
                require('@/assets/music/cuicanmaoxianren.mp3'),
                require('@/assets/music/modaolaotou.mp3'),
                require('@/assets/music/二十九.mp3'),
                require('@/assets/music/虎口脱险.mp3')
            ],
            currentIndex: 0
        };
    },
    mounted() {
        const self = this,
            { $refs } = self;
        $refs.audioPlayer.addEventListener('ended', function () {
            self.onNext();
        });
        console.warn('数据', datas);
    },
    methods: {
        onPlayOrPause() {
            const audio = this.$refs.audioPlayer;
            if (this.isPlaying) {
                audio.pause();
            } else {
                audio.play().catch((error) => {
                    console.error('播放失败:', error);
                });
            }
            this.isPlaying = !this.isPlaying;
        },
        onPause() {
            if (this.isPlaying) {
                this.onPlayOrPause();
            }
        },
        onStart() {
            if (!this.isPlaying) {
                this.onPlayOrPause();
            }
        },
        onNext() {
            const self = this,
                { $refs } = self;
            if (!self.isPlaying) {
                self.currentIndex = 0;
            } else {
                self.currentIndex++;
            }
            if (self.currentIndex >= self.musics.length) {
                self.currentIndex = self._getRandomInt(0, self.musics.length - 2);
            }
            $refs.audioPlayer.src = self.musics[self.currentIndex];
            self.isPlaying = false;
            self.onPlayOrPause();
        },
        onPrev() {
            const self = this,
                { $refs } = self;
            if (!self.isPlaying) {
                self.currentIndex = 0;
            } else {
                self.currentIndex--;
            }
            if (self.currentIndex < 0) {
                self.currentIndex = self._getRandomInt(1, self.musics.length - 1);
            }
            $refs.audioPlayer.src = self.musics[self.currentIndex];
            self.isPlaying = false;
            self.onPlayOrPause();
        },
        _getRandomInt(min, max) {
            min = Math.ceil(min); // 向上取整
            max = Math.floor(max); // 向下取整
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    }
};
</script>
<style scoped>
@import '@/assets/css/home.css';
</style>
