<template>
    <div id="app">
        <bl-music />
        <!--        <div class="bl-cont">
                    <div class="logo-svg-cont">
                        <img class="logo-svg" src="@/assets/image/logo.svg" alt="Blove logo" />
                    </div>
                </div>
                <bl-music class="content-container" />
                &lt;!&ndash;        <HelloWorld class="content-container" msg="Blove For You" />&ndash;&gt;
                <audio ref="audioPlayer" src="@/assets/music/凡人诀.mp3"></audio>
                <div class="audio-control">
                    <i class="music-icon bl-icon bl-icon-Music-1"></i>
                    <i class="music-action-icon bl-icon bl-icon-skip-previous-fill" @click="onPrev"></i>
                    <i v-if="isPlaying" class="music-action-icon bl-icon bl-icon-pause1" @click="onPause"></i>
                    <i v-else class="music-action-icon bl-icon bl-icon-play-arrow-fill" @click="onStart"></i>
                    <i class="music-action-icon bl-icon bl-icon-skip-next-fill" @click="onNext"></i>
                </div>-->
    </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue';
// eslint-disable-next-line no-unused-vars
import { datas } from '@/assets/data';
import BlMusic from '@/components/music.vue';

export default {
    name: 'App',
    components: {
        BlMusic,
        // eslint-disable-next-line vue/no-unused-components
        HelloWorld
    },
    data() {
        return {
            isPlaying: false,
            musics: [
                require('./assets/music/凡人诀.mp3'),
                require('./assets/music/cuicanmaoxianren.mp3'),
                require('./assets/music/modaolaotou.mp3'),
                require('./assets/music/二十九.mp3'),
                require('./assets/music/虎口脱险.mp3')
            ],
            currentIndex: 0
        };
    },
    mounted() {
        // document.addEventListener('click', this.playMusic);
        // document.addEventListener('dblclick', this.stopMusic);
        // const self = this,
        //     { $refs } = self;
        // $refs.audioPlayer.addEventListener('ended', function () {
        //     self.onNext();
        // });
        // console.warn(datas);
    },
    beforeDestroy() {
        // document.removeEventListener('click', this.playMusic);
    },
    methods: {
        onPlayOrPause() {
            const audio = this.$refs.audioPlayer;
            if (this.isPlaying) {
                audio.pause();
            } else {
                audio.play().catch((error) => {
                    console.error('播放失败:', error);
                });
            }
            this.isPlaying = !this.isPlaying;
        },
        onPause() {
            if (this.isPlaying) {
                this.onPlayOrPause();
            }
        },
        onStart() {
            if (!this.isPlaying) {
                this.onPlayOrPause();
            }
        },
        onNext() {
            const self = this,
                { $refs } = self;
            if (!self.isPlaying) {
                self.currentIndex = 0;
            } else {
                self.currentIndex++;
            }
            if (self.currentIndex >= self.musics.length) {
                self.currentIndex = self._getRandomInt(0, self.musics.length - 2);
            }
            $refs.audioPlayer.src = self.musics[self.currentIndex];
            self.isPlaying = false;
            self.onPlayOrPause();
        },
        onPrev() {
            const self = this,
                { $refs } = self;
            if (!self.isPlaying) {
                self.currentIndex = 0;
            } else {
                self.currentIndex--;
            }
            if (self.currentIndex < 0) {
                self.currentIndex = self._getRandomInt(1, self.musics.length - 1);
            }
            $refs.audioPlayer.src = self.musics[self.currentIndex];
            self.isPlaying = false;
            self.onPlayOrPause();
        },
        _getRandomInt(min, max) {
            min = Math.ceil(min); // 向上取整
            max = Math.floor(max); // 向下取整
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
    }
};
</script>
<style lang="css">
@import '@/assets/css/default.css';
/*@import url('//at.alicdn.com/t/c/font_3429489_hemd50dp5j.css');*/

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    color: #2c3e50;
    /*margin-top: 4vw;*/
}

.bl-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; /* 使用相对视口宽度的单位 */
    padding: 8px 0;
}

.logo-svg-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10vw; /* 使用相对视口宽度的单位 */
    max-width: 100px; /* 设置最大宽度 */
    height: 10vw; /* 使用相对视口高度的单位，保持比例 */
    max-height: 100px; /* 设置最大高度 */
    border-radius: 50%;
    border: 2px solid #42b983;
    padding: 2px;
}

.logo-svg {
    width: 100%;
    height: 100%;
    min-width: 60px;
    min-height: 60px;
}

.audio-control {
    position: fixed; /* 固定位置 */
    bottom: 0; /* 距离底部0像素 */
    left: 50%; /* 水平居中 */
    transform: translateX(-50%); /* 水平居中调整 */
    padding: 10px 0;
    background-color: #42b983;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 15px;
    user-select: none;
    z-index: 1000; /* 确保按钮在其他内容上方 */
    width: 100%;
}

/*.audio-control::before {
    content: '\266B\00A0';
    font-weight: bolder;
}*/

.music-action-icon {
    font-weight: 900;
    font-size: x-large;
    margin-left: 18px; /* 为图标与内容之间添加一些间距 */
    cursor: pointer; /* 鼠标悬停时显示手型光标 */
}

.music-icon {
    font-weight: 900;
    font-size: x-large;
}

.content-container {
    margin-bottom: 15vw;
}
</style>
